import { Forms, UnityHeading } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { GreyForm } from "@/components/ISVMapping/GreyForm.component";
import { mapVendorToVendorName } from "@/components/ISVMapping/helpers";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { useMapLoadableMemoized, useQueryLoadable } from "@/modules/loadable";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import { useFieldValue } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import { map } from "lodash/fp";
import qs from "qs";
import React, { useCallback } from "react";
import { ISV_VENDOR } from ".";
import { MAPPER_DIRECTION } from "../General.step";

export const useVendorTypes = ({ direction, vendor }) => {
    const { isvMappingService } = useDic();

    return useQueryLoadable(async () => {
        return isvMappingService.getVendorEntityTypes({ direction, vendor });
    }, [isvMappingService, direction, vendor]);
};

const useEnableEntityNameValidator = ({
    isvConnectionId,
    skipValidationFor,
}) => {
    const { axiosService } = useDic();
    return useCallback(
        async value =>
            skipValidationFor && skipValidationFor === value
                ? Forms.success()
                : axiosService
                      .get(
                          `/api/isv-mappings/validate-base-file-pattern?${qs.stringify(
                              { isvConnectionId, baseFilePattern: value },
                          )}`,
                      )
                      .then(({ data: isValid }) =>
                          isValid
                              ? Forms.success()
                              : Forms.error(
                                    t(
                                        "adminReports-modal.form.name.validation.exists",
                                        { value },
                                    ),
                                ),
                      )
                      .catch(e => Forms.error(getErrorMessage(e))),
        [skipValidationFor],
    );
};

export const EnableEntity = ({ direction, isvConnection, initialValues }) => {
    const vendorTypes = useVendorTypes({
        direction: direction,
        vendor: isvConnection.vendor,
    });

    const vendorTypesOptionsLoadable = useMapLoadableMemoized(
        vendorTypes.loadable,
        map(type => ({
            value: type.name,
            label: type.name,
        })),
    );

    const formId = Forms.useFormId();
    const selectedEntity = useFieldValue({ formId, name: "vendorEntityType" });

    const entityNameRequired =
        (vendorTypes.loadable.valueMaybe() ?? []).find(
            type => type.name === selectedEntity,
        )?.requiresName ?? false;

    const enableEntityNameValidator = useEnableEntityNameValidator({
        isvConnectionId: isvConnection.id,
        skipValidationFor: initialValues?.baseFilePattern,
    });

    const baseFilePatternValidator = Forms.validators.failOnFirst(
        [
            Forms.pmValidators.isRequired,
            direction === MAPPER_DIRECTION.UPLOAD &&
            isvConnection.vendor === ISV_VENDOR.ENABLE
                ? enableEntityNameValidator
                : undefined,
        ].filter(Boolean),
    );

    return (
        <GreyForm>
            <UnityHeading size={4}>
                {t("isv-connections.data-mapping.step.entities.entity", {
                    vendorName: mapVendorToVendorName(isvConnection.vendor),
                })}
            </UnityHeading>
            <Forms.FieldGroup grid>
                <Forms.Fields.Select
                    required
                    name="vendorEntityType"
                    label={t(
                        "isv-connections.data-mapping.step.entities.entity-type",
                        {
                            vendorName: mapVendorToVendorName(
                                isvConnection.vendor,
                            ),
                        },
                    )}
                    allowClear={false}
                    showSearch
                    validator={Forms.pmValidators.isRequired}
                    {...getLoadableSelectProps(vendorTypesOptionsLoadable)}
                />
                {entityNameRequired && (
                    <Forms.Fields.Input
                        required
                        name="baseFilePattern"
                        label={t(
                            "isv-connections.data-mapping.step.entities.entity-name",
                            {
                                vendorName: mapVendorToVendorName(
                                    isvConnection.vendor,
                                ),
                            },
                        )}
                        placeholder="Set entity name"
                        validator={baseFilePatternValidator}
                    />
                )}
            </Forms.FieldGroup>
        </GreyForm>
    );
};
