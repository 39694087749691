import { useExportMutation } from "@/components/DesignSystem/Table/hooks/useExportCsv.hook";
import { useDic } from "@/components/Dic/useDic.hook";
import { useBreadcrumbButtons } from "@/components/hooks/useBreadcrumbButtons.hook";
import { getEventWFTrackName as getTrackName } from "@/mixpanel/buttonNames";
import { isLoading } from "@/modules/loadable";
import { EO_EDIT_PERMISSION } from "@/security/permission.utils";

export const useEventWorkflowBreadcrumbButtons = ({
    accountId,
    columns,
    showExport,
}) => {
    const {
        locationRouterService,
        accountAppLocations: { eventWorkflowNewLocation },
    } = useDic();

    const exportMutation = useExportMutation({
        fileName: "event-workflows.csv",
        exportUrl: `/api/accounts/${accountId}/event-workflows/export`,
        columns,
    });

    useBreadcrumbButtons(
        () =>
            [
                {
                    permissions: [EO_EDIT_PERMISSION],
                    label: "location.accountApp.eventOrchestration.eventWorkflows.create",
                    onClick: () =>
                        locationRouterService.navigate(
                            eventWorkflowNewLocation,
                        ),
                    track: { name: getTrackName("New") },
                },
                showExport
                    ? {
                          label: "button.export-csv",
                          key: "button.export-csv",
                          size: "small",
                          type: "secondary",
                          onClick: () => exportMutation.mutate(),
                          loading: isLoading(exportMutation),
                      }
                    : null,
            ].filter(Boolean),
        [
            eventWorkflowNewLocation,
            exportMutation,
            locationRouterService,
            showExport,
        ],
    );
};
