import { ColumnsSelectModal } from "@/components/DesignSystem";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useMemoByDeepEquality } from "@/components/hooks/useMemoByDeepEquality.hook";
import { t } from "@/translations";
import React, { useMemo } from "react";
import { useTrackButtonClick } from "../../mixpanel/hooks/useTrackButtonClick.hook";
import { useTableSettings } from "../PageLayout/useSetMenuItems.hook";

export const DislocatedPreferencesDropdown = React.memo(
    ({
        columns,
        changedPreference,
        selectedPreference,
        panelColumns,
        setPanelColumns,
        visible,
        preferences,
        ...props
    }) => {
        const setColumns = useCurrentHandler(props.setColumns);
        const onPreferenceSelect = useCurrentHandler(props.onPreferenceSelect);
        const onAddPreference = useCurrentHandler(props.onAddPreference);
        const onSavePreference = useCurrentHandler(props.onSavePreference);
        const onResetPreference = useCurrentHandler(props.onResetPreference);
        const openPreferenceManager = useCurrentHandler(
            props.openPreferenceManager,
        );
        const { trackHandler } = useTrackButtonClick();

        // TODO: move tracking of nested items from DislocatedPreferencesDropdown to ButtonMenu
        const menuItems = useMemo(
            () => [
                {
                    actions: {
                        setColumns: trackHandler(setColumns, {
                            defaultName: "set-fields-visibility-table",
                        }),
                    },
                    anchor: t(
                        "table.preference-dropdown.fields-visibility.table",
                    ),
                    component: ColumnsSelectModal,
                    columns,
                    "data-test": "columns",
                    // TODO: onClick supported here?
                },
                ...(panelColumns && setPanelColumns
                    ? [
                          {
                              actions: {
                                  setColumns: trackHandler(setPanelColumns, {
                                      defaultName:
                                          "set-fields-visibility-panel",
                                  }),
                              },
                              anchor: t(
                                  "table.preference-dropdown.fields-visibility.panel",
                              ),
                              component: ColumnsSelectModal,
                              columns: panelColumns, // TODO: fix UC, missing/mistyped columns cause infinite render loop
                              "data-test": "panel-fields",
                          },
                      ]
                    : []),
                {
                    component: "span",
                    style: { color: "silver", cursor: "not-allowed" },
                    children: "Preferences",
                    divider: true,
                },
                ...preferences.map(pref => ({
                    anchor: `${
                        pref.name === selectedPreference?.name ? "✔️" : " "
                    } ${pref.name}`,
                    "data-test": `${pref.name}-${
                        pref.name === selectedPreference?.name
                            ? "active"
                            : "inactive"
                    }`,
                    disabled: false,
                    onClick: trackHandler(() => onPreferenceSelect(pref), {
                        defaultName: "select-preference",
                    }),
                })),
                {
                    anchor: t(
                        "table.preference-dropdown.preferences.save-preference",
                    ),
                    disabled:
                        !selectedPreference?.name ||
                        !Object.values(changedPreference?.viewState ?? {}).some(
                            v => v,
                        ),
                    onClick: trackHandler(onSavePreference, {
                        defaultName: "save-preference",
                    }),
                    divider: true,
                    "data-test": "save",
                },
                {
                    anchor: t(
                        "table.preference-dropdown.preferences.create-preference",
                    ),
                    disabled: !Object.values(
                        changedPreference?.viewState ?? {},
                    ).some(p => p),
                    onClick: trackHandler(onAddPreference, {
                        defaultName: "create-preference",
                    }),
                    "data-test": "create",
                },
                {
                    anchor: t(
                        "table.preference-dropdown.preferences.reset-preference",
                    ),
                    disabled: !Object.values({
                        ...changedPreference?.viewState,
                        ...selectedPreference?.viewState,
                    }).some(p => p),
                    onClick: trackHandler(onResetPreference, {
                        defaultName: "reset-preference",
                    }),
                    "data-test": "reset",
                },
                {
                    anchor: t(
                        "table.preference-dropdown.preferences.manage-preference",
                    ),
                    disabled: !preferences?.length,
                    onClick: trackHandler(openPreferenceManager, {
                        defaultName: "manage-preferences",
                    }),
                    divider: true,
                    "data-test": "manage",
                },
            ],
            // TODO: memoize columns upstream or useMemoByEquality
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [
                changedPreference,
                preferences,
                selectedPreference,
                onAddPreference,
                onPreferenceSelect,
                onResetPreference,
                onSavePreference,
                openPreferenceManager,
                setColumns,
            ],
        );

        useTableSettings({
            id: props.preferencesType,
            visible,
            items: useMemoByDeepEquality(menuItems),
        });

        return null;
    },
);
