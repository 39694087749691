import { Forms } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { fromEOSch } from "@/components/EventSchedulers/EventSchedulerForm";
import { defaultParserConfigValues } from "@/components/FileParsing/FileParsingFields";
import { isTransactionDateColumnRequired } from "@/components/ISVMapping/steps/Fields.step";
import { MAPPER_EMPTY_VALUE } from "@/components/ISVMapping/steps/General.step";
import { SYNCHRONIZATION_TRIGGER } from "@/components/ISVMapping/steps/Synchronization.step";
import { ROW_ENTITIES } from "@/components/Mappers/MapperTableWithCustomization/MapperTableWithCustomization";
import { debounceAsync } from "@/utils/promises/promise.utils";
import { getErrorMessage } from "@/utils/state/error.utils";
import { size } from "lodash";
import { keys, omit, pick, pipe } from "lodash/fp";
import moment from "moment";
import { useCallback, useMemo } from "react";

export const mapUploadedFileFormValues = formValues => ({
    parserConfig: pick(keys(defaultParserConfigValues), formValues),
    fileId: formValues?.uploadedFile?.id,
    ...(isTransactionDateColumnRequired({
        direction: formValues.type,
        vendor: formValues.vendor,
        vendorEntityType: formValues.vendorEntityType,
    })
        ? {
              transactionDateColumn: formValues.transactionDateColumn,
          }
        : {}),
    mapper: (formValues.mapper ?? []).map(omit([ROW_ENTITIES.converter])),
});

export const mapBasicFormValues = values => {
    const formValues = pipe(fromEOSch)(values);
    return {
        name: formValues.name,
        type: formValues.type,
        vendorEntityType: formValues.vendorEntityType,
        baseFilePattern: formValues?.baseFilePattern,
        pfxEntityType: formValues.pfxEntityType,
        pfxEntityName: formValues?.pfxEntityName,
        customEmptyStringValue:
            formValues.sendEmptyValueAs === MAPPER_EMPTY_VALUE.CUSTOM_STRING
                ? formValues.customEmptyStringValue
                : null,
        enabled: formValues.enabled,
        ...(formValues.schedulerType === SYNCHRONIZATION_TRIGGER.IMMEDIATELY
            ? {}
            : {
                  scheduler: {
                      type: formValues.schedulerDefinition.type,
                      interval: formValues.schedulerDefinition?.interval,
                      eventTime: formValues.schedulerDefinition?.eventTime,
                      atTime: formValues.schedulerDefinition?.atTime,
                      dayOfWeeks: formValues.schedulerDefinition?.dayOfWeeks,
                      dayOfMonth: formValues.schedulerDefinition?.dayOfMonth,
                      dayOfYear: formValues.schedulerDefinition?.dayOfYear,
                      startTime: formValues.schedulerDefinition.startTime,
                      eventTimeZone:
                          formValues.schedulerDefinition.eventTimeZone,
                      cron: formValues.schedulerDefinition?.cron,
                      endTime: formValues.schedulerDefinition.endTime,
                  },
              }),
    };
};
export const mapAllValuesFromForm = formValues => {
    const basicFormValues = mapBasicFormValues(formValues);
    const fileValues = mapUploadedFileFormValues(formValues);

    return {
        ...basicFormValues,
        ...fileValues,
    };
};

const toMoment = (value, format) => {
    return value ? moment(value, format) : undefined;
};
export const mapToInitValues = isvMapper => {
    const { scheduler, ...restValues } = isvMapper;
    return {
        ...restValues,
        parserConfig: isvMapper?.parserConfig,
        mapper: (isvMapper.mapper ?? []).map(omit([ROW_ENTITIES.converter])),
        schedulerType: scheduler
            ? SYNCHRONIZATION_TRIGGER.SCHEDULER
            : SYNCHRONIZATION_TRIGGER.IMMEDIATELY,
        ...(scheduler
            ? {
                  "schedulerDefinition.type": scheduler.type,
                  "schedulerDefinition.atTime": toMoment(
                      scheduler?.atTime,
                      fromEOSch.TIME_FORMAT,
                  ),
                  "schedulerDefinition.eventTime": toMoment(
                      scheduler?.eventTime,
                      fromEOSch.TIME_FORMAT,
                  ),
                  "schedulerDefinition.interval": scheduler?.interval,
                  "schedulerDefinition.dayOfWeeks": scheduler?.dayOfWeeks,
                  "schedulerDefinition.dayOfMonth": scheduler?.dayOfMonth,
                  "schedulerDefinition.dayOfYear": scheduler?.dayOfYear,
                  "schedulerDefinition.startTime": toMoment(
                      scheduler.startTime,
                      fromEOSch.DATETIME_FORMAT,
                  ),
                  "schedulerDefinition.eventTimeZone": scheduler.eventTimeZone,
                  "schedulerDefinition.cron": scheduler.cron,
                  "schedulerDefinition.endTime": toMoment(
                      scheduler.endTime,
                      fromEOSch.DATETIME_FORMAT,
                  ),
              }
            : {}),
        sendEmptyValueAs: restValues.customEmptyStringValue
            ? MAPPER_EMPTY_VALUE.CUSTOM_STRING
            : MAPPER_EMPTY_VALUE.EMPTY_STRING,
    };
};

export const mapVendorToVendorName = vendor => {
    switch (vendor) {
        case "ENABLE":
            return "Enable";
        case "PRICEFX":
            return "Pricefx";
        default:
            return vendor;
    }
};

export const useIsvMapperNameExistsValidator = ({
    connectionId,
    skipValidationFor,
}) => {
    const { isvMappingService } = useDic();
    return useCallback(
        connectionName =>
            skipValidationFor && skipValidationFor === connectionName
                ? Forms.success()
                : isvMappingService
                      .connectionNameExists(connectionId, connectionName)
                      .then(foundConnections =>
                          size(foundConnections) > 0
                              ? Forms.error("Already exists")
                              : Forms.success(),
                      )
                      .catch(e => {
                          if (e?.response?.status === 400) {
                              return Forms.error(e.response?.data?.message);
                          } else {
                              return Forms.error(
                                  getErrorMessage(e.response.data),
                              );
                          }
                      }),
        [skipValidationFor, isvMappingService, connectionId],
    );
};

export const useIsvMapperNameValidator = ({ initialValues, connectionId }) => {
    const connectionExistsValidator = useIsvMapperNameExistsValidator({
        connectionId,
        skipValidationFor: initialValues?.name,
    });

    return useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                debounceAsync(500, connectionExistsValidator),
            ]),
        [connectionExistsValidator],
    );
};
