import { AlertCellWithPopover } from "@/components/AlertCellWithPopover/AlertCellWithPopover";
import {
    ButtonMenu,
    Forms,
    Gap,
    H2,
    H4,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { addLocalFilteredAlerts } from "@/components/EventSchedulers/EventSchedulers.page";
import { ActionFallbackFields } from "@/components/EventWorkflows/ListenerStepForm/ActionFallbackFields";
import { PayloadFields } from "@/components/EventWorkflows/ListenerStepForm/PayloadFields";
import {
    getOptionLabelKey,
    getOptionLabelMaybe,
} from "@/components/EventWorkflows/ListenerStepForm/SourceEventModal";
import { ActionFields } from "@/components/EventWorkflows/ListenerStepForm/listenerStepFormSteps";
import { DestinationFields } from "@/components/EventWorkflows/components/DestinationFields";
import {
    useEOTActionEntitiesOptionsQuery,
    useEOTEntitiesOptionsQuery,
} from "@/components/EventWorkflows/loadables";
import RemoteLayout from "@/components/PageLayout/RemoteLayout";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { t } from "@/translations";
import { flatten, unflatten } from "flat";
import { constant, identity, omit } from "lodash/fp";
import React, { useMemo } from "react";
import { TextOrLoadableText } from "./LoadableText";

const ListenerEntityPage = ({
    visible,
    onSubmit,
    onClose,
    isEdit,
    disabled,
    initialValues: initialValuesProp,
    modalExtraProps: {
        accountId,
        eoTypesQuery,
        isSingleSource = false,
        singleSource,
    },
}) => {
    useSetBackButtonAction(onClose);

    const { formId, Form, setValues, setTouched } = Forms.useForm({
        onSubmit: ({ values }) => {
            const nested = unflatten(values, { safe: true });
            const final = nested.fallbackOptions.enabled
                ? nested
                : omit(["fallbackOptions"], nested);

            return onSubmit(final);
        },
    });
    const initialValues = useMemo(() => {
        if (!initialValuesProp) return initialValuesProp;
        else return flatten(initialValuesProp);
    }, [initialValuesProp]);
    useSetValidatedInitialValues({ initialValues, setValues, setTouched }, [
        initialValues,
    ]);

    // const sourceType = SourceFields.useSourceType({ formId });
    // const eotSourceEntitiesQuery = useEOTEntitiesOptionsQuery({
    //     accountId,
    //     eoType: sourceType,
    //     canFetch: !!sourceType,
    // });

    // const eotSourceEventsQuery = useEOTEventOptionsQuery({
    //     accountId,
    //     eoType: sourceType,
    //     canFetch: !!sourceType,
    // });
    // const loadableSourceEventTypeValidator = useLoadableOptionsValidatorMaybe({
    //     isRequired: true,
    //     loadable: eotSourceEventsQuery.loadable,
    // });

    const title = t(
        disabled
            ? "event-wf.listener-form.actions.title.view"
            : isEdit
            ? "event-wf.listener-form.actions.title.edit"
            : "event-wf.listener-form.actions.title.new",
    );

    return (
        <RemoteLayout>
            <UnityLayout>
                <UnityLayout.Header
                    breadcrumbs={[
                        {
                            label: "Workflows",
                        },
                        {
                            label: "Listener",
                        },
                        {
                            label: title,
                        },
                    ]}
                    onClickBack={onClose}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Text>
                        {t("event-wf.listener-form.steps.action.perex")}{" "}
                    </Text>
                    <Gap size="small" />
                    <Form>
                        <H4>{t("event-wf.form.target")}</H4>
                        <DestinationFields
                            accountId={accountId}
                            isSingleSource={isSingleSource}
                            singleSource={singleSource}
                        />
                        <H4>{t("event-wf.form.action")}</H4>
                        <ActionFields
                            accountId={accountId}
                            targetTypeName="targetType"
                            targetIdName="targetId"
                            setValues={setValues}
                        />
                        <PayloadFields
                            heading={t("event-wf.listener-form.payload")}
                            prefixKey="payload"
                            checkboxName={`useOriginalPayload`}
                        />
                        <Gap size="large" />
                        <ActionFallbackFields
                            prefixKey="fallbackOptions"
                            accountId={accountId}
                            isSingleSource={isSingleSource}
                            singleSource={singleSource}
                        />
                    </Form>
                </UnityLayout.Content>

                <UnityLayout.Footer
                    actionButtonsLeft={
                        <ButtonMenu
                            buttons={[
                                {
                                    formId,
                                    label: t(
                                        "event-wf.listener-form.actions.save",
                                    ),
                                    type: "primary",
                                    visible: !disabled,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </RemoteLayout>
    );
};
ListenerEntityPage.propTypes = {};

export const ListenerActionStep = ({
    allStepsProps: { accountId, isSingleSource, singleSource },
}) => {
    const actionsColumns = [
        {
            name: DestinationFields.fieldNames.destinationType,
            label: t("event-wf.listener-form.actions.column.targetType"),
            type: fieldTypes.TEXT,
        },
        {
            name: getOptionLabelKey(DestinationFields.fieldNames.destinationId),
            label: t("event-wf.listener-form.actions.column.targetName"),
            type: fieldTypes.TEXT,
            render: (text, record) => (
                <TextOrLoadableText
                    text={record.destinationName}
                    title={record.destinationId}
                    hidden={!record.destinationId}
                    useQuery={useEOTEntitiesOptionsQuery}
                    queryParams={{
                        accountId,
                        eoType: record.destinationType,
                        canFetch:
                            !!record.destinationType && !!record.destinationId,
                    }}
                    getText={({ query }) =>
                        getOptionLabelMaybe(
                            record.destinationId,
                            query.loadable,
                        )
                    }
                />
            ),
        },
        {
            name: "targetType",
            label: t("event-wf.listener-form.actions.column.actionType"),
            type: fieldTypes.TEXT,
        },
        {
            name: "targetId",
            label: t("event-wf.listener-form.actions.column.actionId"),
            type: fieldTypes.TEXT,
            render: (text, record) =>
                record.invalid ? (
                    <AlertCellWithPopover
                        popoverTitle={"No longer exists"}
                        popoverContent={`${
                            record.targetLabel ?? "The target"
                        } does not exist anymore.`}
                        content={"No longer exists"}
                    />
                ) : (
                    <TextOrLoadableText
                        text={record.targetLabel}
                        title={text}
                        useQuery={useEOTActionEntitiesOptionsQuery}
                        queryParams={{
                            accountId,
                            eoType: record.destinationType,
                            entityId: record.destinationId,
                            actionType: record.targetType,
                            canFetch:
                                !!record.destinationType &&
                                !!record.destinationId &&
                                !!record.targetType,
                        }}
                        getText={({ query }) =>
                            getOptionLabelMaybe(text, query.loadable)
                        }
                    />
                ),
        },
    ];

    return (
        <>
            <H2>{t("event-wf.listener-form.steps.action.title")}</H2>
            <Text>{t("event-wf.listener-form.steps.action.perex")}</Text>
            <Forms.Fields.EntityEditorTable
                name="actions"
                addButtonProps={{
                    label: t("event-wf.listener-form.actions.addAction"),
                }}
                columns={actionsColumns}
                ModalComponent={ListenerEntityPage}
                getModalExtraProps={() => ({
                    accountId,
                    // eoTypesQuery,
                    isSingleSource,
                    singleSource,
                })}
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                    Forms.pmValidators.createMinLengthValidation(1, {
                        map: identity,
                    }),
                ])}
                addAlertFn={addLocalFilteredAlerts([
                    {
                        prop: "targetId",
                        getVisible: ({ invalid }) => invalid,
                        getType: constant("RED"),
                        getTooltip: constant(t("general.invalid")),
                    },
                ])}
                // rowHeight={36}
            />
        </>
    );
};
